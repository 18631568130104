/**
* Generated automatically at built-time (2024-11-03T10:19:12.684Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "guide-vinkyl",templateKey: "sites/98-f78f816d-e96f-41d1-856b-b927f684f324"};